import { useCallback, useEffect, useState } from 'react'
import { SearchChargesTotalResponse } from '@open-api/ump/charge-invoice'
import { chargeApi } from '@root/api/ump/charge-invoice'
import { uuid } from '@root/utils/generateUuid'
import { authHelper } from '@root/utils/authHelper/AuthHelper'

interface TotalChargesReqProps {
  hidePaid?: boolean
  noFilterCharges?: boolean
}

export const useTotalCharges = ({ hidePaid, noFilterCharges }: TotalChargesReqProps) => {
  const [subscriptionsInfo, setSubscriptionsInfo] = useState<SearchChargesTotalResponse>(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [uId, setUid] = useState(authHelper?.userProfile?.userId)

  const fetcher = useCallback(
    async (userId: string) => {
      setIsLoading(true)

      try {
        await chargeApi
          .getChargesTotalsUsingPOST('mtsmon_site', {
            userId,
            requestId: uuid(),
            hidePaid,
            noFilterCharges,
          })
          .then(({ data }) => {
            if (!data.errorCode) {
              setSubscriptionsInfo(data)
              setError(null)
            } else {
              setError(data)
            }
          })
          .catch((e) => setError(e))
          .finally(() => setIsLoading(false))
      } catch (error) {
        setError(error)
      }
    },
    [hidePaid, noFilterCharges]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      const userId = authHelper?.userProfile?.userId

      setUid(userId)

      if (userId) {
        clearInterval(interval)
      }
    }, 500)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (uId) {
      fetcher(uId)
    }
  }, [uId])

  return {
    subscriptionsInfo,
    error,
    isLoading,
  }
}
