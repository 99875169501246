import { useCallback, useEffect, useState } from 'react'
import { Customer } from '@open-api/ump/client360'
import { client360Api } from '@root/api/ump/client360'
import { authHelper } from '@root/utils/authHelper/AuthHelper'

interface CustomerReqProps {
  system?: string
  id?: string
  onlyInternalRequest?: boolean
  xRequestId?: string
  xConsumerName?: string
}

export const useCustomerInfo = ({ system, id, onlyInternalRequest, xRequestId, xConsumerName }: CustomerReqProps) => {
  const [customerInfo, setCustomerInfo] = useState<Customer>(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [uPhone, setUPhone] = useState(authHelper?.userProfile?.mobilePhone)

  const fetcher = useCallback(
    async (phone: string) => {
      setIsLoading(true)

      try {
        await client360Api
          .getCustomer(phone, system, id, onlyInternalRequest, xRequestId, xConsumerName)
          .then(({ data }) => {
            setCustomerInfo(data)
          })
          .catch((e) => setError(e))
          .finally(() => setIsLoading(false))
      } catch (error) {
        setError(error)
      }
    },
    [id, onlyInternalRequest, system, xConsumerName, xRequestId]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      const userId = authHelper?.userProfile?.userId

      setUPhone(userId)

      if (userId) {
        clearInterval(interval)
      }
    }, 500)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (uPhone) {
      fetcher(uPhone)
    }
  }, [uPhone])

  return {
    customerInfo,
    error,
    isLoading,
  }
}
