import { useState } from 'react'
import {
  CreateSubscriptionRequestTypeEnum,
  CreateSubscriptionResponse,
  ParamsAutopayment,
  SearchParameters,
} from '@open-api/ump/charge-invoice'
import { subscriptionApi } from '@root/api/ump/charge-invoice'
import { uuid } from '@root/utils/generateUuid'

interface CreateSubscriptionReqProps {
  autoPaymentParams?: ParamsAutopayment
  maxSumAutoPay?: number
  payerName?: string
  serviceId: string
  subscriptionName: string
  subscriptionParams: SearchParameters
  type: CreateSubscriptionRequestTypeEnum
  userId: string
  onSuccess?: (data: CreateSubscriptionResponse) => void
  onError?: (error) => void
}

export const useCreateSubscription = () => {
  const [isLoading, setIsLoading] = useState(false)

  const createSubscription = async ({
    autoPaymentParams,
    maxSumAutoPay,
    userId,
    serviceId,
    subscriptionParams,
    subscriptionName,
    payerName,
    type,
    onSuccess,
    onError,
  }: CreateSubscriptionReqProps) => {
    setIsLoading(true)

    try {
      const { data } = await subscriptionApi.createSubscriptionUsingPOST('mtsmon_site', {
        requestId: uuid(),
        serviceId,
        subscriptionName,
        payerName,
        subscriptionParams,
        type,
        userId,
        autoPaymentParams,
        maxSumAutoPay,
      })

      if (!data.errorMessage) {
        if (onSuccess) onSuccess(data)
      } else if (onError) onError(new Error(data.errorMessage))
    } catch (error) {
      if (onError) onError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return { createSubscription, isLoading }
}
