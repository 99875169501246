// tslint:disable
/**
 * Приложение для автозаполнения данных для формы клиента
 * Owner: client360 team
 *
 * The version of the OpenAPI document: 1.3.0_7c2176dd08afc0446206886dbd8b427944992289
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  addressType?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  fullAddress?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  postalCode?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  countryCode?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  country?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  region?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  regionType?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  rayon?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  rayonType?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  cityType?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  cityArea?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  cityAreaType?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  settlement?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  settlementType?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  street?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  streetType?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  house?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  stroenie?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  korpus?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  flat?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  kladrCode?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  fiasCode?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  fiasLevel?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  composedAddress?: string
}
/**
 *
 * @export
 * @interface BatchGrhubCustomer
 */
export interface BatchGrhubCustomer {
  /**
   *
   * @type {string}
   * @memberof BatchGrhubCustomer
   */
  fullName?: string
  /**
   *
   * @type {string}
   * @memberof BatchGrhubCustomer
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof BatchGrhubCustomer
   */
  middleName?: string
  /**
   *
   * @type {string}
   * @memberof BatchGrhubCustomer
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof BatchGrhubCustomer
   */
  birthDate?: string
  /**
   *
   * @type {string}
   * @memberof BatchGrhubCustomer
   */
  birthPlace?: string
  /**
   *
   * @type {string}
   * @memberof BatchGrhubCustomer
   */
  phone?: string
  /**
   *
   * @type {BatchGrhubDocument}
   * @memberof BatchGrhubCustomer
   */
  document?: BatchGrhubDocument
}
/**
 *
 * @export
 * @interface BatchGrhubDocument
 */
export interface BatchGrhubDocument {
  /**
   *
   * @type {string}
   * @memberof BatchGrhubDocument
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof BatchGrhubDocument
   */
  series?: string
  /**
   *
   * @type {string}
   * @memberof BatchGrhubDocument
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof BatchGrhubDocument
   */
  issueDate?: string
  /**
   *
   * @type {string}
   * @memberof BatchGrhubDocument
   */
  issuedBy?: string
  /**
   *
   * @type {string}
   * @memberof BatchGrhubDocument
   */
  departCode?: string
}
/**
 *
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  requestId?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  fullName?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  middleName?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  gender?: CustomerGenderEnum
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  birthDate?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  birthPlace?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  deathDate?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  maritalStatus?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  inn?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  snils?: string
  /**
   *
   * @type {boolean}
   * @memberof Customer
   */
  vip?: boolean
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  citizenship?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  dependantsCount?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  sourceChannel?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  organization?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  job?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  branch?: string
  /**
   *
   * @type {boolean}
   * @memberof Customer
   */
  taxResident?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Customer
   */
  monetaryResident?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Customer
   */
  isFullyIdent?: boolean
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  category?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  identificationLevel?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  actualityDate?: string
  /**
   *
   * @type {boolean}
   * @memberof Customer
   */
  isMtsSubscriber?: boolean
  /**
   *
   * @type {Array<Email>}
   * @memberof Customer
   */
  emails?: Array<Email>
  /**
   *
   * @type {Array<Phone>}
   * @memberof Customer
   */
  phones?: Array<Phone>
  /**
   *
   * @type {Array<Document>}
   * @memberof Customer
   */
  documents?: Array<Document>
  /**
   *
   * @type {Array<Address>}
   * @memberof Customer
   */
  addresses?: Array<Address>
  /**
   *
   * @type {Array<ExtId>}
   * @memberof Customer
   */
  extIds?: Array<ExtId>
  /**
   *
   * @type {boolean}
   * @memberof Customer
   */
  isDeleted?: boolean
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  sourceSystem?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  custId?: string
}

/**
 * @export
 * @enum {string}
 */
export enum CustomerGenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNKNOWN = 'UNKNOWN',
}

/**
 *
 * @export
 * @interface CustomerFuzzySearchRequest
 */
export interface CustomerFuzzySearchRequest {
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  requestId?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  fullName?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  middleName?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  gender?: CustomerFuzzySearchRequestGenderEnum
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  birthDate?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  birthPlace?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  deathDate?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  maritalStatus?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  inn?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  snils?: string
  /**
   *
   * @type {boolean}
   * @memberof CustomerFuzzySearchRequest
   */
  vip?: boolean
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  citizenship?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  dependantsCount?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  sourceChannel?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  organization?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  job?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  branch?: string
  /**
   *
   * @type {boolean}
   * @memberof CustomerFuzzySearchRequest
   */
  taxResident?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CustomerFuzzySearchRequest
   */
  monetaryResident?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CustomerFuzzySearchRequest
   */
  isFullyIdent?: boolean
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  category?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  identificationLevel?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  actualityDate?: string
  /**
   *
   * @type {boolean}
   * @memberof CustomerFuzzySearchRequest
   */
  isMtsSubscriber?: boolean
  /**
   *
   * @type {Array<Email>}
   * @memberof CustomerFuzzySearchRequest
   */
  emails?: Array<Email>
  /**
   *
   * @type {Array<Phone>}
   * @memberof CustomerFuzzySearchRequest
   */
  phones?: Array<Phone>
  /**
   *
   * @type {Array<Document>}
   * @memberof CustomerFuzzySearchRequest
   */
  documents?: Array<Document>
  /**
   *
   * @type {Array<Address>}
   * @memberof CustomerFuzzySearchRequest
   */
  addresses?: Array<Address>
  /**
   *
   * @type {Array<ExtId>}
   * @memberof CustomerFuzzySearchRequest
   */
  extIds?: Array<ExtId>
  /**
   *
   * @type {boolean}
   * @memberof CustomerFuzzySearchRequest
   */
  isDeleted?: boolean
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  sourceSystem?: string
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchRequest
   */
  custId?: string
  /**
   *
   * @type {number}
   * @memberof CustomerFuzzySearchRequest
   */
  minMatchScope?: number
  /**
   *
   * @type {Array<string>}
   * @memberof CustomerFuzzySearchRequest
   */
  includeSourceData?: Array<CustomerFuzzySearchRequestIncludeSourceDataEnum>
  /**
   *
   * @type {boolean}
   * @memberof CustomerFuzzySearchRequest
   */
  includeWholeList?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum CustomerFuzzySearchRequestGenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNKNOWN = 'UNKNOWN',
}
/**
 * @export
 * @enum {string}
 */
export enum CustomerFuzzySearchRequestIncludeSourceDataEnum {
  RBO = 'RBO',
  SBFL = 'SBFL',
  MLC = 'MLC',
}

/**
 *
 * @export
 * @interface CustomerFuzzySearchResponse
 */
export interface CustomerFuzzySearchResponse {
  /**
   *
   * @type {string}
   * @memberof CustomerFuzzySearchResponse
   */
  requestId?: string
  /**
   *
   * @type {Array<CustomerWithSources>}
   * @memberof CustomerFuzzySearchResponse
   */
  customers?: Array<CustomerWithSources>
}
/**
 *
 * @export
 * @interface CustomerWithSources
 */
export interface CustomerWithSources {
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  requestId?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  fullName?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  middleName?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  gender?: CustomerWithSourcesGenderEnum
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  birthDate?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  birthPlace?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  deathDate?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  maritalStatus?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  inn?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  snils?: string
  /**
   *
   * @type {boolean}
   * @memberof CustomerWithSources
   */
  vip?: boolean
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  citizenship?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  dependantsCount?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  sourceChannel?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  organization?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  job?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  branch?: string
  /**
   *
   * @type {boolean}
   * @memberof CustomerWithSources
   */
  taxResident?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CustomerWithSources
   */
  monetaryResident?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CustomerWithSources
   */
  isFullyIdent?: boolean
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  category?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  identificationLevel?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  actualityDate?: string
  /**
   *
   * @type {boolean}
   * @memberof CustomerWithSources
   */
  isMtsSubscriber?: boolean
  /**
   *
   * @type {Array<Email>}
   * @memberof CustomerWithSources
   */
  emails?: Array<Email>
  /**
   *
   * @type {Array<Phone>}
   * @memberof CustomerWithSources
   */
  phones?: Array<Phone>
  /**
   *
   * @type {Array<Document>}
   * @memberof CustomerWithSources
   */
  documents?: Array<Document>
  /**
   *
   * @type {Array<Address>}
   * @memberof CustomerWithSources
   */
  addresses?: Array<Address>
  /**
   *
   * @type {Array<ExtId>}
   * @memberof CustomerWithSources
   */
  extIds?: Array<ExtId>
  /**
   *
   * @type {boolean}
   * @memberof CustomerWithSources
   */
  isDeleted?: boolean
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  sourceSystem?: string
  /**
   *
   * @type {string}
   * @memberof CustomerWithSources
   */
  custId?: string
  /**
   *
   * @type {Array<Customer>}
   * @memberof CustomerWithSources
   */
  sources?: Array<Customer>
}

/**
 * @export
 * @enum {string}
 */
export enum CustomerWithSourcesGenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNKNOWN = 'UNKNOWN',
}

/**
 *
 * @export
 * @interface Document
 */
export interface Document {
  /**
   *
   * @type {boolean}
   * @memberof Document
   */
  primary?: boolean
  /**
   *
   * @type {string}
   * @memberof Document
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof Document
   */
  series?: string
  /**
   *
   * @type {string}
   * @memberof Document
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof Document
   */
  issueDate?: string
  /**
   *
   * @type {string}
   * @memberof Document
   */
  issuedBy?: string
  /**
   *
   * @type {string}
   * @memberof Document
   */
  departCode?: string
  /**
   *
   * @type {boolean}
   * @memberof Document
   */
  valid?: boolean
  /**
   *
   * @type {string}
   * @memberof Document
   */
  expiryDate?: string
}
/**
 *
 * @export
 * @interface Email
 */
export interface Email {
  /**
   *
   * @type {boolean}
   * @memberof Email
   */
  primary?: boolean
  /**
   *
   * @type {string}
   * @memberof Email
   */
  emailType?: string
  /**
   *
   * @type {string}
   * @memberof Email
   */
  email?: string
}
/**
 *
 * @export
 * @interface ExtId
 */
export interface ExtId {
  /**
   *
   * @type {string}
   * @memberof ExtId
   */
  system?: string
  /**
   *
   * @type {string}
   * @memberof ExtId
   */
  id?: string
}
/**
 *
 * @export
 * @interface GetCustomersFromGrhubRequest
 */
export interface GetCustomersFromGrhubRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof GetCustomersFromGrhubRequest
   */
  phones?: Array<string>
}
/**
 *
 * @export
 * @interface MtsBankErrorInfo
 */
export interface MtsBankErrorInfo {
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  details?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  uuid?: string
}
/**
 *
 * @export
 * @interface MtsBankErrorResponse
 */
export interface MtsBankErrorResponse {
  /**
   *
   * @type {MtsBankErrorInfo}
   * @memberof MtsBankErrorResponse
   */
  error?: MtsBankErrorInfo
}
/**
 *
 * @export
 * @interface Phone
 */
export interface Phone {
  /**
   *
   * @type {boolean}
   * @memberof Phone
   */
  primary?: boolean
  /**
   *
   * @type {string}
   * @memberof Phone
   */
  phoneType?: string
  /**
   *
   * @type {string}
   * @memberof Phone
   */
  number?: string
}

/**
 * CustomerControllerApi - axios parameter creator
 * @export
 */
export const CustomerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary customer
     * @param {string} [phone]
     * @param {string} [system]
     * @param {string} [id]
     * @param {boolean} [onlyInternalRequest]
     * @param {string} [xRequestId]
     * @param {string} [xConsumerName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomer(
      phone?: string,
      system?: string,
      id?: string,
      onlyInternalRequest?: boolean,
      xRequestId?: string,
      xConsumerName?: string,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/b/client360/customer`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (phone !== undefined) {
        localVarQueryParameter['phone'] = phone
      }

      if (system !== undefined) {
        localVarQueryParameter['system'] = system
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id
      }

      if (onlyInternalRequest !== undefined) {
        localVarQueryParameter['onlyInternalRequest'] = onlyInternalRequest
      }

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['X-Request-Id'] = String(xRequestId)
      }

      if (xConsumerName !== undefined && xConsumerName !== null) {
        localVarHeaderParameter['X-Consumer-Name'] = String(xConsumerName)
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary customer/fuzzy-search
     * @param {string} xConsumerName
     * @param {CustomerFuzzySearchRequest} customerFuzzySearchRequest
     * @param {string} [xRequestId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerFuzzySearch(
      xConsumerName: string,
      customerFuzzySearchRequest: CustomerFuzzySearchRequest,
      xRequestId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'xConsumerName' is not null or undefined
      if (xConsumerName === null || xConsumerName === undefined) {
        throw new RequiredError(
          'xConsumerName',
          'Required parameter xConsumerName was null or undefined when calling getCustomerFuzzySearch.'
        )
      }
      // verify required parameter 'customerFuzzySearchRequest' is not null or undefined
      if (customerFuzzySearchRequest === null || customerFuzzySearchRequest === undefined) {
        throw new RequiredError(
          'customerFuzzySearchRequest',
          'Required parameter customerFuzzySearchRequest was null or undefined when calling getCustomerFuzzySearch.'
        )
      }
      const localVarPath = `/b/client360/customer/fuzzy-search`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['X-Request-Id'] = String(xRequestId)
      }

      if (xConsumerName !== undefined && xConsumerName !== null) {
        localVarHeaderParameter['X-Consumer-Name'] = String(xConsumerName)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof customerFuzzySearchRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(customerFuzzySearchRequest !== undefined ? customerFuzzySearchRequest : {})
        : customerFuzzySearchRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary customers
     * @param {string} xConsumerName
     * @param {GetCustomersFromGrhubRequest} getCustomersFromGrhubRequest
     * @param {string} [xRequestId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomersFromGrhub(
      xConsumerName: string,
      getCustomersFromGrhubRequest: GetCustomersFromGrhubRequest,
      xRequestId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'xConsumerName' is not null or undefined
      if (xConsumerName === null || xConsumerName === undefined) {
        throw new RequiredError(
          'xConsumerName',
          'Required parameter xConsumerName was null or undefined when calling getCustomersFromGrhub.'
        )
      }
      // verify required parameter 'getCustomersFromGrhubRequest' is not null or undefined
      if (getCustomersFromGrhubRequest === null || getCustomersFromGrhubRequest === undefined) {
        throw new RequiredError(
          'getCustomersFromGrhubRequest',
          'Required parameter getCustomersFromGrhubRequest was null or undefined when calling getCustomersFromGrhub.'
        )
      }
      const localVarPath = `/b/client360/customers`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['X-Request-Id'] = String(xRequestId)
      }

      if (xConsumerName !== undefined && xConsumerName !== null) {
        localVarHeaderParameter['X-Consumer-Name'] = String(xConsumerName)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof getCustomersFromGrhubRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(getCustomersFromGrhubRequest !== undefined ? getCustomersFromGrhubRequest : {})
        : getCustomersFromGrhubRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CustomerControllerApi - functional programming interface
 * @export
 */
export const CustomerControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary customer
     * @param {string} [phone]
     * @param {string} [system]
     * @param {string} [id]
     * @param {boolean} [onlyInternalRequest]
     * @param {string} [xRequestId]
     * @param {string} [xConsumerName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomer(
      phone?: string,
      system?: string,
      id?: string,
      onlyInternalRequest?: boolean,
      xRequestId?: string,
      xConsumerName?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer> {
      const localVarAxiosArgs = CustomerControllerApiAxiosParamCreator(configuration).getCustomer(
        phone,
        system,
        id,
        onlyInternalRequest,
        xRequestId,
        xConsumerName,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary customer/fuzzy-search
     * @param {string} xConsumerName
     * @param {CustomerFuzzySearchRequest} customerFuzzySearchRequest
     * @param {string} [xRequestId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerFuzzySearch(
      xConsumerName: string,
      customerFuzzySearchRequest: CustomerFuzzySearchRequest,
      xRequestId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerFuzzySearchResponse> {
      const localVarAxiosArgs = CustomerControllerApiAxiosParamCreator(configuration).getCustomerFuzzySearch(
        xConsumerName,
        customerFuzzySearchRequest,
        xRequestId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary customers
     * @param {string} xConsumerName
     * @param {GetCustomersFromGrhubRequest} getCustomersFromGrhubRequest
     * @param {string} [xRequestId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomersFromGrhub(
      xConsumerName: string,
      getCustomersFromGrhubRequest: GetCustomersFromGrhubRequest,
      xRequestId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchGrhubCustomer> {
      const localVarAxiosArgs = CustomerControllerApiAxiosParamCreator(configuration).getCustomersFromGrhub(
        xConsumerName,
        getCustomersFromGrhubRequest,
        xRequestId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * CustomerControllerApi - factory interface
 * @export
 */
export const CustomerControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary customer
     * @param {string} [phone]
     * @param {string} [system]
     * @param {string} [id]
     * @param {boolean} [onlyInternalRequest]
     * @param {string} [xRequestId]
     * @param {string} [xConsumerName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomer(
      phone?: string,
      system?: string,
      id?: string,
      onlyInternalRequest?: boolean,
      xRequestId?: string,
      xConsumerName?: string,
      options?: any
    ): AxiosPromise<Customer> {
      return CustomerControllerApiFp(configuration).getCustomer(
        phone,
        system,
        id,
        onlyInternalRequest,
        xRequestId,
        xConsumerName,
        options
      )(axios, basePath)
    },
    /**
     *
     * @summary customer/fuzzy-search
     * @param {string} xConsumerName
     * @param {CustomerFuzzySearchRequest} customerFuzzySearchRequest
     * @param {string} [xRequestId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerFuzzySearch(
      xConsumerName: string,
      customerFuzzySearchRequest: CustomerFuzzySearchRequest,
      xRequestId?: string,
      options?: any
    ): AxiosPromise<CustomerFuzzySearchResponse> {
      return CustomerControllerApiFp(configuration).getCustomerFuzzySearch(
        xConsumerName,
        customerFuzzySearchRequest,
        xRequestId,
        options
      )(axios, basePath)
    },
    /**
     *
     * @summary customers
     * @param {string} xConsumerName
     * @param {GetCustomersFromGrhubRequest} getCustomersFromGrhubRequest
     * @param {string} [xRequestId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomersFromGrhub(
      xConsumerName: string,
      getCustomersFromGrhubRequest: GetCustomersFromGrhubRequest,
      xRequestId?: string,
      options?: any
    ): AxiosPromise<BatchGrhubCustomer> {
      return CustomerControllerApiFp(configuration).getCustomersFromGrhub(
        xConsumerName,
        getCustomersFromGrhubRequest,
        xRequestId,
        options
      )(axios, basePath)
    },
  }
}

/**
 * CustomerControllerApi - object-oriented interface
 * @export
 * @class CustomerControllerApi
 * @extends {BaseAPI}
 */
export class CustomerControllerApi extends BaseAPI {
  /**
   *
   * @summary customer
   * @param {string} [phone]
   * @param {string} [system]
   * @param {string} [id]
   * @param {boolean} [onlyInternalRequest]
   * @param {string} [xRequestId]
   * @param {string} [xConsumerName]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public getCustomer(
    phone?: string,
    system?: string,
    id?: string,
    onlyInternalRequest?: boolean,
    xRequestId?: string,
    xConsumerName?: string,
    options?: any
  ) {
    return CustomerControllerApiFp(this.configuration).getCustomer(
      phone,
      system,
      id,
      onlyInternalRequest,
      xRequestId,
      xConsumerName,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary customer/fuzzy-search
   * @param {string} xConsumerName
   * @param {CustomerFuzzySearchRequest} customerFuzzySearchRequest
   * @param {string} [xRequestId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public getCustomerFuzzySearch(
    xConsumerName: string,
    customerFuzzySearchRequest: CustomerFuzzySearchRequest,
    xRequestId?: string,
    options?: any
  ) {
    return CustomerControllerApiFp(this.configuration).getCustomerFuzzySearch(
      xConsumerName,
      customerFuzzySearchRequest,
      xRequestId,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary customers
   * @param {string} xConsumerName
   * @param {GetCustomersFromGrhubRequest} getCustomersFromGrhubRequest
   * @param {string} [xRequestId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerControllerApi
   */
  public getCustomersFromGrhub(
    xConsumerName: string,
    getCustomersFromGrhubRequest: GetCustomersFromGrhubRequest,
    xRequestId?: string,
    options?: any
  ) {
    return CustomerControllerApiFp(this.configuration).getCustomersFromGrhub(
      xConsumerName,
      getCustomersFromGrhubRequest,
      xRequestId,
      options
    )(this.axios, this.basePath)
  }
}
