import { useCallback, useEffect, useState } from 'react'
import {
  Subscription,
  SubscriptionListRequestStateEnum,
  SubscriptionListRequestTypeEnum,
} from '@open-api/ump/charge-invoice'
import { subscriptionApi } from '@root/api/ump/charge-invoice'
import { uuid } from '@root/utils/generateUuid'
import { authHelper } from '@root/utils/authHelper/AuthHelper'

interface SubscriptionsReqProps {
  apId?: string
  serviceId?: string
  state?: SubscriptionListRequestStateEnum
  subscriptionId?: string
  type?: SubscriptionListRequestTypeEnum
}

export const useSubscriptions = ({ apId, serviceId, state, subscriptionId, type }: SubscriptionsReqProps) => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [uId, setUid] = useState(authHelper?.userProfile?.userId)

  const fetcher = useCallback(
    async (userId: string) => {
      setIsLoading(true)

      try {
        await subscriptionApi
          .getSubscriptionsUsingPOST('mtsmon_site', {
            userId,
            requestId: uuid(),
            apId,
            serviceId,
            state,
            subscriptionId,
            type,
          })
          .then(({ data }) => {
            if (!data.errorCode && data?.subscriptions) {
              setSubscriptions(data.subscriptions)
              setError(null)
            } else {
              setError(data)
            }
          })
          .catch((e) => setError(e))
          .finally(() => setIsLoading(false))
      } catch (error) {
        setError(error)
      }
    },
    [apId, serviceId, state, subscriptionId, type]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      const userId = authHelper?.userProfile?.userId

      setUid(userId)

      if (userId) {
        clearInterval(interval)
      }
    }, 500)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (uId) {
      fetcher(uId)
    }
  }, [uId])

  return {
    subscriptions,
    error,
    isLoading,
  }
}
