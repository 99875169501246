import { useState } from 'react'
import { DeleteSubscriptionResponse } from '@open-api/ump/charge-invoice'
import { subscriptionApi } from '@root/api/ump/charge-invoice'
import { uuid } from '@root/utils/generateUuid'

interface DeleteSubscriptionReqProps {
  subscriptionId: string
  userId: string
  onSuccess?: (data: DeleteSubscriptionResponse) => void
  onError?: (error) => void
}

export const useDeleteSubscription = () => {
  const [isLoading, setIsLoading] = useState(false)

  const deleteSubscription = async ({ userId, subscriptionId, onSuccess, onError }: DeleteSubscriptionReqProps) => {
    setIsLoading(true)

    try {
      const { data } = await subscriptionApi.deleteSubscriptionsUsingPOST('mtsmon_site', {
        requestId: uuid(),
        userId,
        subscriptionId,
      })

      if (!data.errorMessage) {
        if (onSuccess) onSuccess(data)
      } else if (onError) onError(new Error(data.errorMessage))
    } catch (error) {
      if (onError) onError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return { deleteSubscription, isLoading }
}
