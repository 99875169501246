import { useState } from 'react'
import { SearchChargesResultResponse } from '@open-api/ump/charge-invoice'
import { chargeApi } from '@root/api/ump/charge-invoice'
import { uuid } from '@root/utils/generateUuid'

interface ChargesByTicketIdReq {
  searchTicketId?: string
  userId: string
  serviceId?: string
  hidePaid?: boolean
  noFilterCharges?: boolean
  onSuccess?: (data: SearchChargesResultResponse) => void
  onError?: (error) => void
}

export const useChargesByTicketId = () => {
  const [isLoading, setIsLoading] = useState(false)

  const getChargesByTicketId = async ({
    userId,
    serviceId,
    searchTicketId,
    onSuccess,
    onError,
  }: ChargesByTicketIdReq) => {
    setIsLoading(true)

    try {
      const { data } = await chargeApi.getSearchChargesResultUsingPOST('mtsmon_site', {
        requestId: uuid(),
        searchTicketId,
        userId,
        hidePaid: true,
        noFilterCharges: false,
        serviceId: serviceId === '17523' || serviceId === '17562' ? undefined : serviceId,
      })

      if (!data.errorMessage) {
        if (onSuccess) onSuccess(data)
      } else if (onError) onError(new Error(data.errorMessage))
    } catch (error) {
      if (onError) onError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return { getChargesByTicketId, isLoading }
}
