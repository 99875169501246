import { useState } from 'react'
import { SearchChargesBySubscriptionsResponse } from '@open-api/ump/charge-invoice'
import { chargeApi } from '@root/api/ump/charge-invoice'
import { uuid } from '@root/utils/generateUuid'

interface ChargesTicketBySubscriptionsReq {
  subscriptionId?: string
  userId: string
  serviceId?: string
  onSuccess?: (data: SearchChargesBySubscriptionsResponse) => void
  onError?: (error) => void
}

export const useChargesTicketBySubscriptions = () => {
  const [isLoading, setIsLoading] = useState(false)

  const getChargesTicketBySubscriptions = async ({
    userId,
    serviceId,
    subscriptionId,
    onSuccess,
    onError,
  }: ChargesTicketBySubscriptionsReq) => {
    setIsLoading(true)

    try {
      const { data } = await chargeApi.searchChargesBySubscriptionsUsingPOST('mtsmon_site', {
        requestId: uuid(),
        serviceId,
        subscriptionId,
        userId,
      })

      if (!data.errorMessage) {
        if (onSuccess) onSuccess(data)
      } else if (onError) onError(new Error(data.errorMessage))
    } catch (error) {
      if (onError) onError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return { getChargesTicketBySubscriptions, isLoading }
}
