import { useCallback, useEffect, useRef, useState } from 'react'
import { Payee } from '@open-api/ump/catalog-manager'
import { catalogManagerApi } from '@root/api/ump/catalog-manager'
import { uuid } from '@root/utils/generateUuid'

interface PayeesReqProps {
  id?: string
  categoryName?: string
  showHidden?: boolean
  showFromHiddenCategories?: boolean
  showInvoiceSearchOnly?: boolean
  noInitialFetch?: boolean
}

export const usePayees = ({
  id,
  categoryName,
  showHidden,
  showFromHiddenCategories,
  showInvoiceSearchOnly,
  noInitialFetch,
}: PayeesReqProps) => {
  const renderAfterCalled = useRef(false)
  const [payees, setPayees] = useState<Payee[]>([])
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const fetcher = useCallback(
    async ({ id, categoryName, showHidden, showFromHiddenCategories, showInvoiceSearchOnly }: PayeesReqProps) => {
      setIsLoading(true)

      try {
        await catalogManagerApi
          .getPayees('b', uuid(), id, categoryName, showHidden, showFromHiddenCategories, showInvoiceSearchOnly)
          .then(({ data }) => {
            if (data.payees) {
              setPayees(data.payees)
            }
          })
          .catch((e) => setError(e))
          .finally(() => setIsLoading(false))
      } catch (error) {
        setError(error)
      }
    },
    []
  )

  useEffect(() => {
    if (!renderAfterCalled.current && !noInitialFetch) {
      fetcher({ id, categoryName, showHidden, showFromHiddenCategories, showInvoiceSearchOnly })
    }

    renderAfterCalled.current = true
  }, [fetcher])

  return {
    payees,
    error,
    isLoading,
    fetcher,
  }
}
